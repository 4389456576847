import React, {useEffect, useState} from "react";
import { graphql } from "gatsby"
import { Navigation } from "../components/global/nav"
import { Footer } from "../components/global/footer";
import { FaCompass } from "react-icons/fa"
import "../styles.css"
import Breadcrumbs from "../components/global/breadcrumbs";
import SEO from "../components/global/seo";
import CookiePrompt from "../components/global/cookies-prompt";

const PolicyTemplate = props => {
   const [artDescription, setArtDescription] = useState('');

   const content = props.data.contentfulInternalPolicy.content.internal.content;
   const string = props.data.contentfulInternalPolicy.navigation.internal.content;
   const json = JSON.parse(string)
   const array = Object.entries(json)
   const title = props.data.contentfulInternalPolicy.title;
   const slug = props.data.contentfulInternalPolicy.slug;
   const metadescription = props.data.contentfulInternalPolicy.metaDescription.internal.content;
   const showNavigation = props.data.contentfulInternalPolicy.showNavigation;
   const showArticleDetails = props.data.contentfulInternalPolicy.showArticleDetails;

   // AUTHOR
   const date_update= (new Date(props.data.contentfulInternalPolicy.updatedAt)).toLocaleDateString("en-GB");

   const content_word_count = content.split(" ").length + 1;
   const reading_time = (content_word_count/250).toFixed(0)

   const related_articles = props.data.allContentfulInternalPolicy.edges;

   useEffect(() => {
      window.scrollTo({top: 0})

      setArtDescription(props.data.contentfulInternalPolicy.articleDescription.internal.content)
   }, [])

   const printInternalNavigation = () => {

      let jsx = [];

      for (let i = 0; i < array.length; i++) {
         const el = array[i];
         const keyVAl = Object.entries(el[1])
         
         jsx.push(
            <a key={i} href={`#${keyVAl[0][1]}`}><li>{keyVAl[0][0]}</li></a>
         )
      }

      return jsx;
   }

   const ArtDesc = () => {
      return {__html: artDescription}
   }

   const bodyContent = () => {
      return {__html: content}
   }

   const printRelatedArticles = () => {
      let jsx = [];
      for (let i = 0; i < related_articles.length; i++) {
         const el = related_articles[i];
         
         jsx.push(
            <a href={`/${el.node.slug}`}><li>{el.node.title}</li></a>
         )
      }

      return jsx;
   }
   

   
   return (
      <React.Fragment>
         <SEO 
            title={title}
            slug={slug}
            metadescription={metadescription}
            image=""
            titleTemplate="%s - Smart Plan"
         />
         <CookiePrompt/>
         <Navigation />
         <div className="standard-title-container default">
            <div className="standard-title-inner-container body-inner-width">
               <div className="standard-title-inner-content">
                  <h1>{title}</h1>
                  <p dangerouslySetInnerHTML={ArtDesc()}></p>
                  
                  
               </div>
            </div>
         </div>

         <div className="standard-container">
            <div className="standard-inner-container body-inner-width">
               <div className="standard-content-container">
                  {/* CONTENT */}
                     

                     <div className="content-column">
                        <Breadcrumbs
                           location={props.data.contentfulInternalPolicy.slug}
                        />

                        {/* ARTICLE DETAILS */}
                        {
                           showArticleDetails ? (
                              <div className="article-details-container">
                                 <p className="article-detail article-last-updated">Last updated - <span>{date_update}</span></p>
                                 <p className="article-detail article-reading-time">Estimated reading time - <span>{reading_time} minutes</span></p>
                              </div>
                           ) : null
                        }
                        
                        {/* BODY */}
                        <div className="content-body" dangerouslySetInnerHTML={bodyContent()}>
                        </div>
                     </div>

                  {/* SIDEBAR */}
                  <div className="navigation-column">
                     <div className="navigation-entries-container">

                        {
                           showNavigation ? (
                              <div className="article-navigation-ol-container">
                                 <div className="article-navigation-ol-header">
                                    <FaCompass/>&nbsp;&nbsp;Navigation
                                 </div>

                                 <ol className="article-navigation-ol">
                                    {printInternalNavigation()}
                                 </ol>
                              </div>
                           ) : null
                        }
                        
                        {
                           array.length > 4 ? (
                              <button className="plain-text-btn nav-to-top" onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>Go to the top</button>
                           ) : null
                        }

                        <ul className="related-articles">
                           <h4>Related articles</h4>
                           {printRelatedArticles()}
                        </ul>
                     </div>
                  </div>
               </div>       
            </div>
         </div>
         <Footer/>
      </React.Fragment>
   )
}

export default PolicyTemplate

export const pageQuery = graphql`
   query ContentfulInternalPolicyBySlug( $slug: String!) {
      contentfulInternalPolicy( slug: { eq: $slug }) {
         title
         slug
         metaDescription {
            internal {
               content
            }
         }
         updatedAt
         content {
            internal {
               content
            }
         }
         navigation {
            internal {
               content
            }
         }
         articleDescription {
            internal {
               content
            }
         }
         showArticleDetails,
         showNavigation
      }

      allContentfulInternalPolicy(
         sort: {fields: createdAt}
         filter: {slug: { ne: $slug }}
         limit: 5
      ) {
         edges {
            node {
               slug
               title
            }
         }
      }
   }
`